import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));

export class ContactUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };

    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "20532387",
                    formId: "be20d902-56fc-458d-a859-3d2324d2c7e3",
                    target: '#contact-us-form'
                })
            }
        });
    };

    render() {

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/contact-us/"
                        title="Unleashing The True Potential Of Blockchain Space - Softtik"
                        description="Softtik, one of the world's pioneering blockchain development companies harnesses power of blockchain & crypto to build scalable, asset-agnostic applications."
                    />
                    <main>
                        <div id="wrapper">
                            <div className="contactus-page">
                                <section className='contact-information-sec'>
                                    <div className='get-intouch-secs'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='form-box col-lg-6 col-md-12'>
                                                    <div className='form-inner' id="get-intouch">
                                                        <div className='sec-title text-center'>
                                                            <h2>How Can We Help You?</h2>
                                                            <p>Get in touch and our expert support team will answer all your questions.</p>
                                                        </div>
                                                        {/* <div className='form-area'>
                                                            <iframe src="https://contact-softtik.gr8.com/"></iframe>
                                                        </div> */}
                                                        <div className='hubspot-form'>
                                                            <iframe src="https://share.hsforms.com/1viDZAlb8RY2oWT0jJNLH4wc82w3"></iframe>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='calendly-box col-lg-6 col-md-12'>
                                                    <div className='calendly-inner'>
                                                        <div className='sec-title text-center' style={{ marginBottom: 0 }}>
                                                            <h2>Can’t Wait Enough?</h2>
                                                            <p>Book a Free Consultation with Our Experts Right Away</p>
                                                        </div>
                                                        <div className='calendly-area'>
                                                            <iframe className="calendly-inline-widget" src="https://meetings.hubspot.com/softtik/30min?embed=true" loading="lazy" width="100%" height="730px"></iframe>
                                                            {/* <iframe className="calendly-inline-widget" src="https://calendly.com/softtik-technologies?hide_gdpr_banner=1" loading="lazy" width="520px" height="630px"></iframe> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='contact-information-box col-lg-6 offset-lg-3 col-md-12'>
                                                <div className='contact-information-inner'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Talk to Us</h2>
                                                        <p>Talk to us on the phone or via email</p>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <a href='tel:+12349018706'>
                                                                <i className='fa fa-phone icon'></i>
                                                                +1 (234) 901-8706</a>
                                                        </li>
                                                        <li><a className='active' href='mailto:info@softtik.com'>
                                                            <i className='fa fa-envelope icon'></i>
                                                            info@softtik.com</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='map-sec'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='map-box col-12'>
                                                <div className='map-inner'>
                                                    <Tabs orientation="horizontal">
                                                        <TabList>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/ae-flag.webp' alt="flag" /></span>Dubai Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag-1.webp' alt="flag" /></span>California Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag.webp' alt="flag" /></span>Rawalpindi Office</Tab>
                                                            <Tab><span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/flag.webp' alt="flag" /></span>Lahore Office</Tab>
                                                        </TabList>
                                                        <TabPanels>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab Emirates</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225.71298470101962!2d55.30051589970127!3d25.155706148181615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69c9d616266d%3A0xb3fb9c63ffc5370c!2sSoulful!5e0!3m2!1sen!2s!4v1666784109492!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>1765 Landess Ave #546 Milpitas CA 95035</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.829332791597!2d-121.87463648520142!3d37.41750864040746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fce93e595cf69%3A0xa661a4b970fcd129!2s1765%20Landess%20Ave%20%23546%2C%20Milpitas%2C%20CA%2095035%2C%20USA!5e0!3m2!1sen!2s!4v1655708554574!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>Unit# D, Floor# 2, Plot# 68, Box Park Building, Bahria Springs North, Phase 7, Rawalpindi</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.0080569168867!2d73.09579691468467!3d33.52717615266498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfede8cfd2c96b%3A0xa614c2b01ad71be4!2sSofttik%20Technologies!5e0!3m2!1sen!2s!4v1655705960129!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel className="tabs-box">
                                                                <div className="fetured-tab-box">
                                                                    <div className="text-box">
                                                                        <h4><i className='fa fa-map-marker'></i>42-Z Block, 3rd floor, DHA phase 3, Lahore</h4>
                                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27222.70923589772!2d74.33436447434087!3d31.47362425227611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190676e1e0d841%3A0xd14d6b7294fdf8a0!2sDHA%20Phase%203%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1712227978088!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                    </div>
                                                                </div>
                                                            </TabPanel>
                                                        </TabPanels>
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>

                </InnerLayout >
            </>
        )
    }
};

export default ContactUs;